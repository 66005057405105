import Axios from 'axios';

const Client_api = Axios.create({
    baseURL: 'https://172.16.0.100/',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});

export function get_bender_login(bn, pw) {
    const json = JSON.stringify({ bn1: bn, pw2: pw });
    return Client_api.post(`https://172.16.0.100/rest-api/bender_w.php`, json)
        .then(response => {
            //alert(JSON.stringify(response.data))       
            return response.data;
        }).catch(error => {
        });
}

export default Client_api;