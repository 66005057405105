import './App.css';
import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { get_bender_login } from './api'

const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: '#ffff00',
    }
  },
  typography: {
    button: {
      textTransform: "none"
    }
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: '10px',
  },
  div1: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    width: '100%',
    background: 'transparent',
    zoom: '60%',
    transform: 'scale(0.8)',
  },
  avatar: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    width: '100px',
    height: '100px',
    background: '#888888',
    fontSize: 16,
  },
  paper1: {
    position: 'absolute',
    top: '10px',
    left: '150px',
    background: '#666666',
    fontSize: 16,
    padding: '5px',
  },  
  eingabe: {
    padding: theme.spacing(0),
    textAlign: 'left',
    width: 'auto',
    backgroundColor: '#888888',
  },
  bt_login: {
    color: '#ffffff',
    backgroundColor: '#018e41',
    width: '300px',
    height:'84px',
    fontSize: '30px',
    texttransform: "none",
  },
  frame: {
    width: '2000px',
    height: '1200px',
    fontSize: '30px',
    position: 'absolute',
    top: '120px',
    left: '0px',
    texttransform: "none",
  },
}));

function App() {
  const classes = useStyles();
  const [_bn, _set_bn] = useState("");
  const [_pw, _set_pw] = useState("");
  const [_scr, _set_scr] = useState("");
  // const ref_bn = useRef(null);
  // const ref_pw = useRef(null);
  // const ref_bt = useRef(null);

  // useEffect(() => {
  // get_bender_login(_bn, _pw).then(res => {
  //   try {
  //     document.getElementById('txt2').value = res[0].token;
  //   } catch (error) { console.log(error) }
  // })
  // });

  const login_ = async (e) => {
   //window.location = 'https://ln.myds.me';
    get_bender_login(_bn, _pw).then(res => {
      try {
        //document.getElementById('txt2').value = 'https://' + res[0].token.replace('-','.').replace('-','.').replace('-','.');
       
        //_set_scr('https://' + res[0].token.replace('-', '.').replace('-', '.').replace('-', '.'));
        //window.location = 'https://' + res[0].token.replace('-', '.').replace('-', '.').replace('-', '.');
      } catch (error) { console.log(error) }
    })

    e.preventDefault();
    e.stopPropagation();

  }
  const KEY_bn = async (e) => {
    //if (e.key === 'Enter') {
    _set_bn(e.target.value);
    //ref_pw.current.focus();
    e.preventDefault();
    e.stopPropagation();
    //}
  }

  const KEY_pw = async (e) => {
    //if (e.key === 'Enter') {
    _set_pw(e.target.value);
    //ref_bn.current.focus();
    e.preventDefault();
    e.stopPropagation();
    //}
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.div1}>
        {/* <Grid item xs={12}>
          <Avatar variant="square" src={info} className={classes.avatar} />

          <Paper className={classes.paper1}>
            <Grid container spacing={1}>
              <Grid item xs={0}>
                <Paper className={classes.eingabe}>
                  <TextField
                    id='txt0'
                    label=""
                    helperText='Benutzer '
                    autoComplete='off'
                    variant="outlined"
                    className={classes.text}
                    onChange={KEY_bn}
                    inputRef={ref_bn}
                    autoFocus
                  />
                </Paper>
              </Grid>

              <Grid item xs={0}>
                <Paper className={classes.eingabe}>
                  <TextField
                    id='txt1'
                    label=""
                    helperText='Password '
                    autoComplete='off'
                    variant="outlined"
                    className={classes.text}
                    onChange={KEY_pw}
                    inputRef={ref_pw}
                    type='password'
                  //autoFocus
                  />
                </Paper>
              </Grid>

              <Grid item xs={0}>
                <Paper className={classes.eingabe}>
                  <TextField
                    id='txt2'
                    label=""
                    helperText='Password '
                    autoComplete='off'
                    variant="outlined"
                    className={classes.text}
                  //onKeyDown={KEY_pw}
                  //inputRef={ref_pw}
                  //type='password'
                  //autoFocus
                  />
                </Paper>
              </Grid>

              <Button className={classes.bt_login} inputRef={ref_bt} onClick={login_} variant="contained">Login</Button>
            
              <object data={_scr}
                className={classes.frame}
                target="_blank"
                width='100%'
                height="1300"
                type="text/html">
              </object>
           

            </Grid>
          </Paper>
        </Grid>
 */}


      </div>
    </ThemeProvider>
  );
}

export default App;
